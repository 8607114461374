import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import debounce from 'lodash/debounce';

import './index.scss';

import { Text, Link } from 'components/atoms';

import { useWindowSize } from 'utils/windowSizeHook';

const IndustriesCard = ({ data, className, onClick }) => {
  const { title, description, image, private_access, industry_page_url } =
    data || {};
  const { source_url } = image || {};

  const href = (industry_page_url || '').split(/\/\/[a-z0-9.]+/)[1];

  const [touchState, setTouchState] = useState(false);

  const width = useWindowSize();
  const [isDesktop, setIsDesktop] = useState(width > 1024 ? true : false);

  const imageContainerRef = useRef(null);

  if (width) {
    let newWidth = width > 1024 ? true : false;
    if (isDesktop !== newWidth) {
      setIsDesktop(newWidth);
      setTouchState(!!('ontouchstart' in window));
    }
  }

  useEffect(() => {
    setTouchState(!!('ontouchstart' in window));
  }, []);

  const calcPosition = debounce((translateX, translateY, deviationValue) => {
    let imageDeviationX = 0;
    let imageDeviationY = 0;

    let min = 50 - deviationValue;
    let max = 50 + deviationValue;

    if (translateX > min && translateX < max) {
      imageDeviationX = translateX;
    } else {
      if (translateX > max) {
        imageDeviationX = max;
      } else if (translateX < min) {
        imageDeviationX = min;
      }
    }

    if (translateY > min && translateY < max) {
      imageDeviationY = translateY;
    } else {
      if (translateY > max) {
        imageDeviationY = max;
      } else if (translateY < min) {
        imageDeviationY = min;
      }
    }
    return {
      imageDeviationX: imageDeviationX,
      imageDeviationY: imageDeviationY,
    };
  }, 5);

  const changeBackgroundPosition = e => {
    const mousePosX = e.nativeEvent.offsetX;
    const mousePosY = e.nativeEvent.offsetY;

    const imgContainerWidth = Math.ceil(
      imageContainerRef.current.getBoundingClientRect().width
    );
    const imgContainerHeight = Math.ceil(
      imageContainerRef.current.getBoundingClientRect().height
    );

    const deviationValue = 2;

    const translateX = Math.ceil((mousePosX / imgContainerWidth) * 100);
    const translateY = Math.ceil((mousePosY / imgContainerHeight) * 100);

    const position = calcPosition(translateX, translateY, deviationValue);

    const { imageDeviationX, imageDeviationY } = position || {};

    imageContainerRef.current.style.backgroundPosition = `${imageDeviationX ||
      50}% ${imageDeviationY || 50}%`;
  };

  const resetBackgroundPosition = () => {
    imageContainerRef.current.style.backgroundPosition = `50% 50%`;
  };

  return !private_access ? (
    <Link className={cn('industries-card', [className])} href={href}>
      <div>
        <Text tag="p" className="industries-card__title headline-4">
          {title}
        </Text>
        <Text>{description}</Text>
        {!touchState && isDesktop && (
          <div
            className={cn('industries-card__image', {
              'industries-card__image_disabled': touchState,
            })}
            role={'button'}
            aria-label={title}
            ref={imageContainerRef}
            tabIndex={0}
            style={{ backgroundImage: `url(${source_url})` }}
            onMouseMove={changeBackgroundPosition}
            onMouseLeave={resetBackgroundPosition}
          />
        )}
      </div>
    </Link>
  ) : (
    <div
      className="industries-card industries-card_private"
      onClick={onClick}
      onKeyDown={onClick}
      role="button"
      tabIndex={0}
    >
      <Text tag="p" className="industries-card__title headline-4">
        {title}
      </Text>
      <Text>{description}</Text>
    </div>
  );
};

IndustriesCard.defaultProps = {
  className: '',
};

IndustriesCard.propTypes = {
  data: PropTypes.object.isRequired,
  className: PropTypes.string,
  onClick: PropTypes.func,
};

export default IndustriesCard;
