import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import './index.scss';

import { Section, Container, Text } from 'components/atoms';
import IndustriesCard from 'components/molecules/IndustriesCard';
import Breadcrumbs from 'components/molecules/Breadcrumbs';

const Industries = ({ data, breadcrumbsData, className }) => {
  const { caption, industries } = data || {};
  const { title } = caption;

  const industriesCards =
    industries &&
    industries.map((item, index) => {
      return <IndustriesCard data={item} key={index} />;
    });

  return (
    <Section className={cn('industries-section', [className])}>
      <Container>
        <Text className="industries__title" tag="h1">
          {title}
        </Text>
        <div className="industries__cards">{industriesCards}</div>
        <Breadcrumbs
          {...breadcrumbsData}
          className="breadcrumbs_industries"
          isHorizontal={true}
        />
      </Container>
    </Section>
  );
};

Industries.defaultProps = {
  className: '',
};

Industries.propTypes = {
  data: PropTypes.object.isRequired,
  breadcrumbsData: PropTypes.object,
  className: PropTypes.string,
};

export default Industries;
